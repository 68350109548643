import React from 'react';

const DelFr = () => {
    return (
        // <div>
        //         <div style={{fontSize: '18px'}}><b>CONDITIONS DE LIVRAISON:</b></div>
        //         <br/>
        //         CONDITIONS DE LIVRAISON:- <b>29€  (après toutes les remises)</b>
        //
        //         <br/>Jours de livraison:<b>Lun-Sam</b> (dimanche exclus)
        //         <br/><br/>
        //         <b style={{color: 'darkgreen'}}>Symbol vert </b>sur le produit: la livraison sera possible <b> le jour même</b>, après 15:00, <b>si la commande a été envoyée avant 10:00 du matin.</b>
        //         <br/><br/>
        //         <b style={{color: 'goldenrod'}}>les produits disposant du Symbole Jaune</b>  la commande sera livrée selon <b>le nombre de jours maximum qui apparaît dans la description du produit.</b>
        //         <br/><br/>
        //         <b>Tous les produits avec🎅🏻: </b>-  livraison seulement <b>les jours du 29 au 31.12.22, jusqu'à 16:00</b>
        //         (nous recevons les commandes jusqu'à 22.00 à partir du 27.12.22)
        //         <br/><br/>
        //         Les commandes pour  le vendredi <b>le samedi</b>, doivent être réalisées <b>avant 10:00.</b>
        //         <br/><br/>
        // </div>
        <div>
            <div style={{fontSize: '18px'}}><b>CONDICIONES DE LA ENTREGA:</b></div>

            <br/>Días de entrega: <b>Lun-Sáb (excepto domingo)</b>
            <br/><br/>
            <b style={{color: 'darkgreen'}}>Palomita verde </b> en el producto - la entrega será posible <b>el
            mismo
            día, </b> después de las 15:00,<b>si el pedido fuese enviado antes de las 10:00 de la mañana</b>
            <br/><br/>
            <b style={{color: 'goldenrod'}}>Palomita amarilla</b> - el pedido se entregará <b>según el número
            máximo
            de días en la descripción del producto.</b>
            <br/><br/>
            <div style={{fontSize: '18px'}}><b>Condiciones para el Año Nuevo!</b></div>
            <br/>
            - se aceptan pedidos <b>HASTA las 08:00 28/12/23</b> (después - información bajo petición)
            <br/>
            <br/>
            - la entrega se realizará <b>SÓLO el día 30/12/23 de 10:00 a 19:00.</b>
            <br/>
            <br/>
            Importe mínimo del pedido para las entregas en <b>Barcelona ciudad es de 45 euros.</b>
            <br/>
            <br/>
            En los <b>alrededores de la Zona 1 (metro de Barcelona) - el pedido mínimo es de 60 euros.</b>
            <br/>
            <br/>
            Si el coste de envío es inferior de pedido mínimo <b>se cobra 5 euros para la entrega</b>
        </div>

    );
};

export default DelFr;