import React from 'react';
import '../BodyComponents/ProfileBar.scss'

const TotalProfiltProfile = () => {
    return (
        <div className='PersonalDataProfile'>
            <div className='titleText'>Total profit </div>
            
        </div>
    );
};

export default TotalProfiltProfile;