import React from 'react';
import "./CustomSwitch.scss"
const CustomSwitch = () => {
    return (
        <div className="CustomSwitch">
            <input type="checkbox"/>
        </div>
    );
};

export default CustomSwitch;