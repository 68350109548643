import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import {publicRoutes} from "../router";
import Mobile from "./Landing/Mobile/Mobile";
import Desktop from "./Landing/Desktop/Desktop";
const AppRouter = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 900);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 900);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Routes>
            <Route path="/" element={<Navigate replace to="/shop" />} />
            {isMobile ?
                <Route path="/site" element={<Navigate replace to="/mobile/site"/>} />
                :
                <Route path="/mobile/site" element={<Navigate replace to="/site"/>}/>
            }
            {publicRoutes.map(({ path, Component }) => {
                    return <Route key={path} path={path} element={<Component />} exact />;

            })}
            <Route path="*" element={<Navigate replace to="/" />} />
        </Routes>
    );
};

export default AppRouter;