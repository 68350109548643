import React from 'react';

const DelCat = () => {
    return (
        // <div>
        //     <div style={{fontSize: '18px'}}><b>CONDICIONS DE L'ENTREGA:</b></div>
        //     <br/>
        //     El comandament mínim - <b>29€ * (després de tots els descomptes)</b>
        //
        //     <br/>Dies de lliurament: <b>Dilluns a Dissabte</b> (excepte diumenge)
        //     <br/><br/>
        //     <b style={{color: 'darkgreen'}}>Tic verd</b>en el producte: el lliurament serà possible <b>el mateix dia</b>, a partir de les 15:00, <b>sempre i quan el comandament sigui demanat abans de les 10h</b>
        //     <br/><br/>
        //     <b style={{color: 'goldenrod'}}>Si hi han productes amb el Tic groc</b>  el comandament s’entregarà segons el <b>el número màxim dels dies en la descripció del producte</b>
        //     <br/><br/>
        //     <b>Tots els productes amb🎅🏻 </b>- lliurament<b>del 29/12 al 31/12 fins les 16h</b>
        //     (acceptem comandaments fins  les 22h del 27/12)
        //     <br/><br/>
        //     Els comandaments  pel <b>al dissabte</b>, han de realitzar-se <b>el divendres  abans de les 10:00</b>
        //     <br/><br/>
        // </div>
        <div>
            <div style={{fontSize: '18px'}}><b>CONDICIONES DE LA ENTREGA:</b></div>

            <br/>Días de entrega: <b>Lun-Sáb (excepto domingo)</b>
            <br/><br/>
            <b style={{color: 'darkgreen'}}>Palomita verde </b> en el producto - la entrega será posible <b>el
            mismo
            día, </b> después de las 15:00,<b>si el pedido fuese enviado antes de las 10:00 de la mañana</b>
            <br/><br/>
            <b style={{color: 'goldenrod'}}>Palomita amarilla</b> - el pedido se entregará <b>según el número
            máximo
            de días en la descripción del producto.</b>
            <br/><br/>
            <div style={{fontSize: '18px'}}><b>Condiciones para el Año Nuevo!</b></div>
            <br/>
            - se aceptan pedidos <b>HASTA las 08:00 28/12/23</b> (después - información bajo petición)
            <br/>
            <br/>
            - la entrega se realizará <b>SÓLO el día 30/12/23 de 10:00 a 19:00.</b>
            <br/>
            <br/>
            Importe mínimo del pedido para las entregas en <b>Barcelona ciudad es de 45 euros.</b>
            <br/>
            <br/>
            En los <b>alrededores de la Zona 1 (metro de Barcelona) - el pedido mínimo es de 60 euros.</b>
            <br/>
            <br/>
            Si el coste de envío es inferior de pedido mínimo <b>se cobra 5 euros para la entrega</b>
        </div>

    );

};

export default DelCat;