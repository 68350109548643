import React from 'react';

const DelUrk = () => {
    return (
        // <div>
        //         <div style={{fontSize: '18px'}}><b>УМОВИ ДОСТАВКИ: </b></div>
        //         <br/>
        //         Мін. замовлення -  <b>29€  після врахування усіх знижок</b>
        //
        //         <br/>ДНІ доставки: <b>ПН-СБ (крім неділі)</b>
        //
        //         <br/><br/>
        //         <b style={{color: 'darkgreen'}}>Зелена галочка</b> у товарі – доставка можлива <b> у цей же день</b>, після 15:00,
        //         <b>при умові замовлення до 10:00 ранку</b>
        //         <br/><br/>
        //         <b style={{color: 'goldenrod'}}>Жовта галочка </b> – замовлення  буде доставлене  <b>залежно від максимальної кількості днів в описі товара</b>
        //         <br/><br/>
        //         <b>Усі товари з 🎅🏻</b> – для доставки  <b>тільки на 29 – 31.12.22 до 16:00 </b> (замовлення на ці товари – 27.12.22 до 22:00)
        //         <br/><br/>
        //         Для доставки  <b> у суботу </b>, замовлення має бути зроблене  <b>у п`ятницю до 10:00</b>
        //         <br/><br/>
        //
        // </div>
        <div>
            <div style={{fontSize: '18px'}}><b>CONDICIONES DE LA ENTREGA:</b></div>

            <br/>Días de entrega: <b>Lun-Sáb (excepto domingo)</b>
            <br/><br/>
            <b style={{color: 'darkgreen'}}>Palomita verde </b> en el producto - la entrega será posible <b>el
            mismo
            día, </b> después de las 15:00,<b>si el pedido fuese enviado antes de las 10:00 de la mañana</b>
            <br/><br/>
            <b style={{color: 'goldenrod'}}>Palomita amarilla</b> - el pedido se entregará <b>según el número
            máximo
            de días en la descripción del producto.</b>
            <br/><br/>
            <div style={{fontSize: '18px'}}><b>Condiciones para el Año Nuevo!</b></div>
            <br/>
            - se aceptan pedidos <b>HASTA las 08:00 28/12/23</b> (después - información bajo petición)
            <br/>
            <br/>
            - la entrega se realizará <b>SÓLO el día 30/12/23 de 10:00 a 19:00.</b>
            <br/>
            <br/>
            Importe mínimo del pedido para las entregas en <b>Barcelona ciudad es de 45 euros.</b>
            <br/>
            <br/>
            En los <b>alrededores de la Zona 1 (metro de Barcelona) - el pedido mínimo es de 60 euros.</b>
            <br/>
            <br/>
            Si el coste de envío es inferior de pedido mínimo <b>se cobra 5 euros para la entrega</b>
        </div>

    );
};

export default DelUrk;