import React from 'react';

const DelRus = () => {
    return (
        // <div>
        //         <div style={{fontSize: '18px'}}><b>CONDITII DE LIVRARE:</b></div>
        //         <br/>
        //         Comanda minimă - <b> 29€* (după toate reducerile)</b>
        //
        //         <br/>Zile de livrare: <b>luni-sâmbătă</b> (cu excepția duminica)
        //         <br/><br/>
        //         <b style={{color: 'darkgreen'}}>Bifata verde</b> pe produs - livrarea va fi posibila  <b>in aceeasi zi, </b>dupa ora 15:00,<b>daca comanda era trimisa inainte de ora 10:00 dimineata</b>
        //         <br/><br/>
        //         <b style={{color: 'goldenrod'}}>Produse cu bifa galbena</b> comanda va fi livrata in functie de  <b>numarul maxim de zile din descrierea produsului</b>
        //         <br/><br/>
        //         <b>Toate produsele cu🎅🏻 </b>- se livrează doar<b>în zilele de 29-31.12.22, până la ora 16:00.</b>
        //         (primim comenzi - până la ora 22.00 pe 27.12.22)
        //         <br/><br/>
        //         Comenzile pentru  <b>sâmbătă</b>, trebuie plasate  <b>vineri înainte de ora 10:00</b>
        //         <br/><br/>
        // </div>

        <div>
            <div style={{fontSize: '18px'}}><b>CONDICIONES DE LA ENTREGA:</b></div>

            <br/>Días de entrega: <b>Lun-Sáb (excepto domingo)</b>
            <br/><br/>
            <b style={{color: 'darkgreen'}}>Palomita verde </b> en el producto - la entrega será posible <b>el
            mismo
            día, </b> después de las 15:00,<b>si el pedido fuese enviado antes de las 10:00 de la mañana</b>
            <br/><br/>
            <b style={{color: 'goldenrod'}}>Palomita amarilla</b> - el pedido se entregará <b>según el número
            máximo
            de días en la descripción del producto.</b>
            <br/><br/>
            <div style={{fontSize: '18px'}}><b>Condiciones para el Año Nuevo!</b></div>
            <br/>
            - se aceptan pedidos <b>HASTA las 08:00 28/12/23</b> (después - información bajo petición)
            <br/>
            <br/>
            - la entrega se realizará <b>SÓLO el día 30/12/23 de 10:00 a 19:00.</b>
            <br/>
            <br/>
            Importe mínimo del pedido para las entregas en <b>Barcelona ciudad es de 45 euros.</b>
            <br/>
            <br/>
            En los <b>alrededores de la Zona 1 (metro de Barcelona) - el pedido mínimo es de 60 euros.</b>
            <br/>
            <br/>
            Si el coste de envío es inferior de pedido mínimo <b>se cobra 5 euros para la entrega</b>
        </div>

    );
};

export default DelRus;