import React from 'react';

const DelEng = () => {
    return (
        // <div>
        //         <div style={{fontSize: '18px'}}><b>DELIVERY CONDITIONS:</b></div>
        //         <br/>
        //         Minimum order- <b> 29€ * (after all discounts)</b>
        //
        //         <br/>Delivery days: <b>Mon-Sat</b>  (except Sunday)
        //         <br/><br/>
        //         <b style={{color: 'darkgreen'}}> Green tick</b> on the product: delivery will be possible <b>the same day</b>, after 15:00, <b>if the order was sent  before 10:00 am</b>
        //         <br/><br/>
        //         <b style={{color: 'goldenrod'}}>the Yellow Tick</b> -  the order will be delivered according to the  <b>maximum number of days in the product description.</b>
        //         <br/><br/>
        //         <b>All products with🎅🏻 </b>-  to be delivered <b> only on the days from 29-31.12.22, until 16:00</b>
        //         (we receive orders - till 22.00 on 27.12.22)
        //         <br/><br/>
        //         Orders for <b>Saturday</b>,  , must be placed  <b>on Friday before 10:00.Для доставки в </b>
        //         <br/><br/>
        // </div>
        <div>
            <div style={{fontSize: '18px'}}><b>CONDICIONES DE LA ENTREGA:</b></div>

            <br/>Días de entrega: <b>Lun-Sáb (excepto domingo)</b>
            <br/><br/>
            <b style={{color: 'darkgreen'}}>Palomita verde </b> en el producto - la entrega será posible <b>el
            mismo
            día, </b> después de las 15:00,<b>si el pedido fuese enviado antes de las 10:00 de la mañana</b>
            <br/><br/>
            <b style={{color: 'goldenrod'}}>Palomita amarilla</b> - el pedido se entregará <b>según el número
            máximo
            de días en la descripción del producto.</b>
            <br/><br/>
            <div style={{fontSize: '18px'}}><b>Condiciones para el Año Nuevo!</b></div>
            <br/>
            - se aceptan pedidos <b>HASTA las 08:00 28/12/23</b> (después - información bajo petición)
            <br/>
            <br/>
            - la entrega se realizará <b>SÓLO el día 30/12/23 de 10:00 a 19:00.</b>
            <br/>
            <br/>
            Importe mínimo del pedido para las entregas en <b>Barcelona ciudad es de 45 euros.</b>
            <br/>
            <br/>
            En los <b>alrededores de la Zona 1 (metro de Barcelona) - el pedido mínimo es de 60 euros.</b>
            <br/>
            <br/>
            Si el coste de envío es inferior de pedido mínimo <b>se cobra 5 euros para la entrega</b>
        </div>

    );
};

export default DelEng;