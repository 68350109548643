import React from 'react';

const Footer = () => {
    return (
        <footer>
            TPO Production &copy;
        </footer>
    );
};

export default Footer;